import React from 'react';
import { Layout } from '../components/shared/Layout';
import { Link } from 'gatsby';

const FAQPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto">
            <h1>Frequently asked questions</h1>
            <div className="grid md:grid-cols-2 md:gap-4">
              <div className="p-16">
                <h2>I have an idea</h2>
                <p>
                  Do you have an idea that makes The Hague more lovely, fun
                  &amp; awesome?
                </p>
                <a
                  href="https://www.awesomefoundation.org/en/submissions/new?chapter=thehague"
                  className="text-xl"
                >
                  Apply now
                </a>
                <Link to="#idea" className="mx-4 text-xl underline">
                  Read more
                </Link>
              </div>
              <div className="p-16">
                <h2>I want to become a trustee</h2>
                <p>
                  Do you want to want to make The HAgue more awesome by donating
                  €50 a few times a year?
                </p>
                <Link to="/contact" className="text-xl">
                  Apply now
                </Link>
                <Link to="#trustee" className="mx-4 text-xl underline">
                  Read more
                </Link>
              </div>
            </div>
            <div className="p-16">
              <h2>I want to become a partner</h2>
              <p>Sponsor drinks? Host a location? Take pictures?</p>
              <Link to="/contact" className="text-xl">
                Apply now
              </Link>
              <Link to="#partner" className="mx-4 text-xl underline">
                Read more
              </Link>
            </div>
            {/* FAQ */}
            <div className="py-16">
              <h2 id="idea">I have an idea</h2>

              <h3>What are the criteria?</h3>

              <p>
                First of all, the idea should be AWESOME! Second, the idea
                should have a strong connection with The Hague. Third, you
                should be able to boost realize your idea with the €1.000 within
                a few months.
              </p>

              <h3>When is an idea Awesome?</h3>

              <p>
                What’s awesome and what’s not is very personal. It could be
                donating money to help elderly against loneliness by jointly
                making jam of left over fruit. On the other hand, there could be
                winners that convinced the trustees that running a parkour of
                inflatable dinosaurs is awesome. Throughout the years there have
                been hundreds of ideas worldwide, check out this website for
                some inspiration.
              </p>

              <p>We believe your idea is Awesome when</p>
              <ul>
                <li>
                  The idea helps The Hague to become more cool, fun, loving and
                  social, not you more rich
                </li>
                <li>
                  The idea stands out: it evokes surprise, is creative and shows
                  your love for The Hague
                </li>
                <li>
                  You can use the micro-grant of €1000 to execute your idea or
                  to kickstart the further development
                </li>
              </ul>
            </div>

            <div className="py-16">
              <h2 id="trustee">I want to become a trustee</h2>
              <h3>What are the criteria?</h3>

              <p>
                You love ideas that evoke surprise. You love to stimulate people
                that dare to stand out. Every pitch night you hand in 50 euro
                cash. If you are not able to attend you make sure somebody is
                replacing you. That’s it.
              </p>

              <h3>Where can I sign up?</h3>

              <p>
                Send us an email with some basic information about yourself.
                We’ll give you a call to see if there’s a match. Feel free to
                join us during a Pitch Night to experience what it’s like to be
                a Trustee.
              </p>
              <h3>How can I get more information?</h3>
              <p>Send us an email or join the Telegram group!</p>
              <h3>
                You never know… but what if I don’t want to be a trustee
                anymore?
              </h3>
              <p>
                Trustees can move, trustees can have other priorities. We see
                people come and go, and that’s no problem. Let the organization
                know at least one Pitch Night before you leave us.
              </p>
            </div>

            <div className="py-16">
              <h2 id="partner">I want to become a partner</h2>
              <h3>Can I host one of your events?</h3>
              <p>
                Yes! We would love that. If your location can host 50-100
                people, is easy reachable by public transport and has a bar we
                will be easily convinced we should host our event at your place.
              </p>

              <h3>How can I help?</h3>
              <p>
                All help is welcome. We are especially looking for partners that
              </p>
              <ul>
                <li>Provide their venue to host our Pitch Nights</li>
                <li>Like to share beers & soda with us</li>
                <li>Can take pictures and/or create video materials</li>
                <li>
                  Just want to provide us some pocket money for our hosting and
                  marketing
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default FAQPage;
